<template>
  <div class="d-flex flex-column align-center pb-3">
    <div>
      <div class="d-flex flex-column align-center mt-3">
        <v-chip-group
          class="mb-3"
          column
        >
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#benefits"
          >
            <v-icon left>mdi-plus</v-icon>
            Voordelen
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#card"
          >
            <v-icon left>mdi-card</v-icon>
            De kaart
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#cardreader"
          >
            <v-icon left>mdi-credit-card-scan</v-icon>
            Kaartlezer
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#cashier-app"
          >
            <v-icon left>mdi-cash-register</v-icon>
            Kassa applicatie
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#screenshots-webapp"
          >
            <v-icon left>mdi-image-multiple-outline</v-icon>
            Screenshots webapp
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#pricing"
          >
            <v-icon left>mdi-currency-eur</v-icon>
            Prijzen
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/tenants#faq"
          >
            <v-icon left>mdi-frequently-asked-questions</v-icon>
            Veelgestelde vragen
          </v-chip>
        </v-chip-group>
        <v-card
          id="benefits"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Voordelen van Topupz in je club</span>
          </v-card-title>
          <v-card-text>
            <ul>
              <li>lage kost kaarlezer hardware en kassa software</li>
              <li>geef beloningen aan je leden ipv de oudbollige bonnetjes</li>
              <li>vele rapporten, grafieken en inzichten per type verrichting</li>
              <li>volg alle transacties via de webapp op</li>
              <li>een maandelijkse duidelijke factuur</li>
              <li>en nog zoveel meer...</li>
            </ul>
            <v-alert type="info" class="mt-5" color="black">
              <div>Heb je interesse in een demo of wil je intekenen, stuur dan een mailtje naar <a href="mailto:info@topupz.be"><span class="font-weight-bold">info [at] topupz [dot] be</span></a></div>
              <div class="caption">We werken nog aan een volledig online proces, dus ja, voorlopig nog even via de old-fashioned way 😅</div>
            </v-alert>
          </v-card-text>
        </v-card>
        <v-card
          id="card"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-img
            class="white--text align-end"
            :src="require('../assets/card-shot.jpeg')"
          >
          </v-img>
          <v-card-title>
            <span class="title">De kaart</span>
          </v-card-title>
          <v-card-text>
            <ul>
              <li>Beveiligd protocol waardoor ze niet gekopieerd kan worden</li>
              <li>Geef alle leden een kaart die ze nadien zelf kunnen registreren en onmiddellijk kunnen gebruiken</li>
              <li>Elk lid heeft met de kaart een rekening bij de club, die ze ofwel aan de kassa kunnen opladen of indien gewenst ook online via de user webapp met Bancontact (Optionele instelling).</li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card
          id="cardreader"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">De kaartlezer</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-carousel continuous height="" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.xsOnly">
              <v-carousel-item
                v-for="(item,i) in screenshotsScanner"
                :key="i"
              >
                <div class="d-flex flex-column align-center">
                  <v-img :src="item.src" width="250"/>
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
        <v-card
          id="cashier-app"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">De kassa applicatie</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-carousel continuous height="" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.xsOnly">
              <v-carousel-item
                v-for="(item,i) in screenshotsCashier"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
        <v-card
          id="screenshots-webapp"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Screenshots van de webapp</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <div class="pa-5 pt-0">Met de webapp voor tenants kan je de kassa, kaartlezer, producten en nog zoveel meer beheren. Zie hieronder voor enkele screenshots of ontdek zelf door in te loggen.</div>
            <v-carousel continuous height="" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.xsOnly">
              <v-carousel-item
                v-for="(item,i) in screenshotsWebapp"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
        <v-card
          id="pricing"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Prijzen (excl. BTW)</span>
          </v-card-title>
          <v-card-text>
            Volgende fysieke dingen dienen aangekocht te worden:
            <ul>
              <li>Kaartlezer: 350,00 EUR/st</li>
              <li>Kaart: 3,00 EUR/st</li>
              <li>Administratieve -en bezorgingskost per kaartbestelling: 20,00 EUR</li>
            </ul>
            <div class="mt-3">
              Verder is er een maandelijkse abonnementsprijs voor het gebruik van de kassasoftware en de tenant webapp.
            </div>
            <div class="mt-5 text-center">
              <v-card dark class="mx-auto" outlined :max-width="$vuetify.breakpoint.smAndUp ? '300px' : '250px'">
                <v-card-title class="justify-center display-1">Basispakket</v-card-title>
                <v-card-text>
                  <div>Alle features inbegrepen</div>
                  <div>incl 1000 bestellingen <sup>*</sup></div>
                  <div class="display-1 mt-3 primary--text">
                    35,00 EUR
                  </div>
                  <div>per maand</div>
                  <div class="mt-5"><sup>*</sup> 5,00 EUR extra per gestarte schijf van 1000 bestellingen. Niet overdraagbaar.</div>
                </v-card-text>
              </v-card>
              <div class="mt-5 font-weight-bold">Neem zeker contact met ons op indien er sprake is van grote volumes, dan bekijken we samen voor een aangepaste prijs.</div>
            </div>
            <v-alert type="info" class="mt-5" color="black">
              <div>Heb je interesse in een demo of wil je intekenen, stuur dan een mailtje naar <a href="mailto:info@topupz.be"><span class="font-weight-bold">info [at] topupz [dot] be</span></a></div>
              <div class="caption">We werken nog aan een volledig online proces, dus ja, voorlopig nog even via de old-fashioned way 😅</div>
            </v-alert>
          </v-card-text>
        </v-card>
        <v-card
          id="faq"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Veelgestelde vragen</span>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-expansion-panels flat accordion>
                <v-expansion-panel
                  v-for="(item,i) in faq"
                  :key="i"
                >
                  <v-expansion-panel-header class="font-weight-bold">{{ item.question }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(line,j) in item.answers" :key="j" :class="{ 'mb-5' : j != item.answers.length - 1 }">{{ line }}</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomePage',

    data() {
      return {
        faq: [
          {
            question: 'Is de kassa enkel beschikbaar voor een Windows 10 PC met touchscreen?',
            answers: [
              'Momenteel is dit inderdaad een requirement. Er is ook een Mac versie ter beschikking.',
              'In een later stadium gaan er zeker nog andere mogelijkheden zijn. Laat zeker weten waar jullie naar opzoek zijn of je mag ons altijd contacteren voor meer uitleg of planning.'
              ]
          },
          {
            question: 'Ik kan niet alle leden zien in de lijst met gebruikers.',
            answers: [
              'Van zodra het lid zijn/haar kaart gekoppeld heeft via de gebruikers webapp, is de gebruiker pas zichtbaar in de lijst.',
              'Het is dus belangrijk dat de leden niet te lang wachten met hun kaart te koppelen, want ondertussen zijn ze niet beschikbaar in de lijst om beloningen via Topupz te geven.'
            ]
          },
          {
            question: 'Hoe kan ik de producten van volgorde veranderen in de kassa?',
            answers: [
              'De volgorde van de producten komt overeen met deze op de producten pagina in de tenant webapp. Je kan ze daar van volgorde veranderen door drag & drop.',
              'Wist je dat je een product als favoriet kan aanduiden? Favoriete producten komen in de kassa op het eerste tabblad met het hartje 💛'
            ]
          },
          {
            question: 'Waarom is er geen stockbeheer of een boekhoudintegratie?',
            answers: [
              'We trachten de applicatie zo simpel mogelijk te houden en tegemoet te komen aan de noden voor kleine verenigingen.',
              'Via de uitgebreide rapportage kunnen de nodige gegevens uiteraard wel opgevraagd worden voor een gewenste periode.'
            ]
          },
          {
            question: 'Waar kan ik de BTW ingeven of BTW-categoriën maken?',
            answers: [
              'In een eerste fase wordt er geen rekening gehouden met BTW. De ingegeven prijs is de verkoopprijs, ongeacht het BTW-stelsel om tegemoet te komen aan de noden voor kleine verenigingen.',
              'In een volgende release zal dit wel mogelijk worden. Je mag ons altijd contacteren voor meer uitleg of planning.'
            ]
          },
          {
            question: 'Is de kassa een gecertifieerde witte kassa?',
            answers: [
              'Neen, onze klanten zijn zelf verantwoordelijk om na te gaan als ze al dan niet een witte kassa dienen te gebruiken alvorens in te tekenen bij Topupz.'
            ]
          }
        ],
        screenshotsScanner:  [
          {
            src: require('../assets/tenants-screenshots/nl/800/scanner-menu.jpeg')
          },
          {
            src: require('../assets/tenants-screenshots/nl/800/scanner-saldo.jpeg')
          },
          {
            src: require('../assets/tenants-screenshots/nl/800/scanner-order.jpeg')
          }
        ],
        screenshotsCashier:  [
          {
            src: require('../assets/tenants-screenshots/nl/800/cashier-order.png')
          }
        ],
        screenshotsWebapp:  [
          {
            src: require('../assets/tenants-screenshots/nl/800/add-product.png')
          }
        ]
      }
    },
  }
</script>
