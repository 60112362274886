<template>
  <v-app>
    <v-app-bar
      app
      dark
      class="d-flex align-center justify-center"
    >
      <div>
        <v-img
          alt="Topupz Logo"
          class="mr-2 cursorPointer"
          :src="require('./assets/logo_cutout_transparant.png')"
          transition="scale-transition"
          @click="goHome"
        />
      </div>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app dark class="d-flex align-center justify-center">
      <span>&copy; {{ year }}</span>
      <v-btn
        v-if="contextLoginUrl"
        absolute
        dark
        fab
        top
        right
        color="primary"
        class="black--text"
        :href="contextLoginUrl"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import moment from 'moment'

export default {
  name: 'App',

  data: () => ({
    year: moment().format('YYYY')
  }),
  methods: {
    goHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  },
  computed: {
    contextLoginUrl() {
      return this.$application.urls[this.$route.path.slice(1)];
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');

html {
  overflow: auto !important;
}

.v-application {
  font-family: Ubuntu, sans-serif;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #424242 !important;
  background-color: #424242 !important;
}
::-webkit-scrollbar {
  width: 4px !important;
  background-color: #424242 !important;
}
::-webkit-scrollbar-thumb {
  background-color: #9E9E9E !important;
}

.cursorPointer {
  cursor: pointer;
}

.v-carousel__controls__item {
  color: black !important;
}
</style>
