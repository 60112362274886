<template>
  <div class="d-flex flex-column align-center">
    <div class="text-center">
      <h1
        class="pa-6 pb-1"
        :class="$vuetify.breakpoint.mdAndUp ? 'display-1 font-weight-bold ' : 'title'">
        Cashless betalen met je Topupz ledenkaart
      </h1>
    </div>
    <div class="subheading font-weight-regular ma-3">
      Dé oplossing voor sportclubs en verenigingen!
    </div>
    <div
      class="d-flex"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column': ''"

    >
      <v-card
        min-width="300px"
        max-width="300px"
        :class="$vuetify.breakpoint.mdAndUp ? 'ma-3 mr-9' : 'mb-3'"
        shaped
        dark>
        <v-card-title>
          <v-icon
            large
            left
          >
            mdi-account-box-multiple
          </v-icon>
          <span class="title font-weight-light">Lid van een club ?</span>
        </v-card-title>
        <v-card-text>
          <ul v-if="$vuetify.breakpoint.mdAndUp">
            <li>betaal aan de kassa met je topupz ledenkaart</li>
            <li>ontvang beloningen van je club op je account</li>
            <li>laadt je kaart via de webapp of aan de kassa op</li>
            <li>volg je transacties via de webapp op</li>
            <li>en nog zoveel meer...</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="primary black--text"
            :href="$application.urls.users"
          >
            <v-icon class="mr-1">mdi-account-circle</v-icon>
            Inloggen
          </v-btn>

          <v-btn
            color="primary"
            text
            to="/users"
          >
            Meer informatie
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        min-width="300px"
        max-width="300px"
        :class="$vuetify.breakpoint.mdAndUp ? 'ma-3 ml-9' : 'mb-3'"
        shaped
        dark
      >
        <v-card-title>
          <v-icon
            large
            left
          >
            mdi-storefront
          </v-icon>
          <span class="title font-weight-light">Eigenaar van een club ?</span>
        </v-card-title>
        <v-card-text>
          <ul v-if="$vuetify.breakpoint.mdAndUp">
            <li>lage kost kaarlezer hardware en kassa software</li>
            <li>geef beloningen aan je leden ipv de oudbollige bonnetjes</li>
            <li>vele rapporten, grafieken en inzichten per type verrichting</li>
            <li>volg alle transacties via de webapp op</li>
            <li>en nog zoveel meer...</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="primary black--text"
            :href="$application.urls.tenants"
          >
            <v-icon class="mr-1">mdi-account-circle</v-icon>
            Inloggen
          </v-btn>

          <v-btn
            color="primary"
            text
            to="/tenants"
          >
            Meer informatie
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomePage',

    data() {
      return {
      }
    },
  }
</script>
