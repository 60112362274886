<template>
  <div class="d-flex flex-column align-center pb-3">
    <div>
      <div class="d-flex flex-column align-center mt-3">
        <v-chip-group
          class="mb-3"
          column
        >
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/users#benefits"
          >
            <v-icon left>mdi-plus</v-icon>
            Voordelen
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/users#card"
          >
            <v-icon left>mdi-card</v-icon>
            De kaart
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/users#screenshots-webapp"
          >
            <v-icon left>mdi-image-multiple-outline</v-icon>
            Screenshots webapp
          </v-chip>
          <v-chip
            class="ma-1"
            color="primary"
            text-color="black"
            to="/users#faq"
          >
            <v-icon left>mdi-frequently-asked-questions</v-icon>
            Veelgestelde vragen
          </v-chip>
        </v-chip-group>
        <v-card
          id="benefits"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Voordelen als lid van een club met Topupz</span>
          </v-card-title>
          <v-card-text>
            <ul>
              <li>betaal aan de kassa met je topupz ledenkaart</li>
              <li>ontvang beloningen van je club op je account</li>
              <li>laadt je kaart via de webapp of aan de kassa op</li>
              <li>volg je transacties via de webapp op</li>
              <li>en nog zoveel meer...</li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card
          id="card"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-img
            class="white--text align-end"
            :src="require('../assets/card-shot.jpeg')"
          >
          </v-img>
          <v-card-title>
            <span class="title">De kaart</span>
          </v-card-title>
          <v-card-text>
            <ul>
              <li>Beveiligd protocol waardoor ze niet gekopieerd kan worden</li>
              <li>Er kunnen meerdere kaarten aan je clubaccount gekoppeld worden</li>
              <li>In een latere fase kan je je kaart ook bij verschillende aangesloten clubs gebruiken, welliswaar met elk hun eigen rekening</li>
              <li>Nog geen kaart van je club ontvangen? Vraag ernaar of spoor ze aan om ook Topupz aan te bieden 😛</li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card
          id="screenshots-webapp"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Screenshots van de webapp</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-carousel continuous height="" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.xsOnly">
              <v-carousel-item
                v-for="(item,i) in screenshotsWebapp"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
        <v-card
          id="faq"
          class="mt-6"
          shaped
          :min-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
          :max-width="$vuetify.breakpoint.mdAndUp ? '900px' : $vuetify.breakpoint.smAndUp ? '550px' : '300px'"
        >
          <v-card-title>
            <span class="title" style="word-break: normal;">Veelgestelde vragen</span>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-expansion-panels flat accordion>
                <v-expansion-panel
                  v-for="(item,i) in faq"
                  :key="i"
                >
                  <v-expansion-panel-header class="font-weight-bold">{{ item.question }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(line,j) in item.answers" :key="j" :class="{ 'mb-5' : j != item.answers.length - 1 }">{{ line }}</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomePage',

    data() {
      return {
        faq: [
            {
              question: 'Help, ik heb een Topupz kaart gekregen! Wat nu?',
              answers: [
                'Je kan als gebruiker een account aanmaken, via je gmail account of door zelf een Topupz account aan te maken. Eens aangemeld, kan je je kaart aan je club koppelen adhv het kaartnummer. De kaart is dan onmiddellijk klaar voor gebruik.',
                'Je kan de registratie best niet te lang uitstellen, want anders loop je het risico dat je club je geen beloningen kan geven via Topupz.'
                ]
            },
            {
              question: 'Kan iemand mijn verloren kaart gebruiken?',
              answers: [
                'Je kan je kaart via de webapp deactiveren zodat een verloren kaart niet door iemand anders gebruikt kan worden.',
                'De kaart kan ten alle tijde via de webapp ook terug geactiveerd worden, moest hij plots in een andere broek weer tevoorschijn komen.']
            },
            {
              question: 'Hoe kan ik mijn saldo bekijken?',
              answers: [
                'Het eenvoudigste is via de webapp, in het overzicht "Lidmaatschappen" is het rekeningsaldo per club zichtbaar.',
                'Via de Topupz kaartlezers in je club kan je ook het saldo consulteren adhv je Topupz kaart.',
                'Na elke betaling met je Topupz kaart, wordt tevens het resterende saldo tijdens de bevestiging kort getoond.'
              ]
            },
            {
              question: 'Wat zijn de mogelijkheden om mijn kaart op te laden?',
              answers: [
                'Momenteel kan dit enkel aan de kassa van je club met cash geld.',
                'Weldra is er een integratie voorzien waardoor de club, indien gewenst, ook opladingen en/of betalingen via Bancontact kan aanbieden. Aan de kassa kan dit dan door een QR code op de kaartlezer te scannen met bv. je Payconiq app. Via de webapp zal je je kaart dan ook kunnen oploaden via een QR code of via bv. de Payconiq app.'
              ]
            },
            {
              question: 'Kan ik mijn kaart in een andere club gebruiken?',
              answers: [
                'Momenteel is er een kaart per club.',
                'We werken momenteel aan het concept van een generieke kaart waardoor je met dezelfde kaart bij meerdere clubs een rekening kan openen en betalingen kan uitvoeren. Je zal bij elke club wel apart je rekening hebben, maar dat was hetzelfde in de tijd van de bonnetjes 😉'
              ]
            },
            {
              question: 'Moeten mijn kinderen allemaal zelf een account aanmaken?',
              answers: [
                'Die keuze mag je volledig zelf nemen: elk apart een account of meerdere kaarten op dezelfde account.',
                'In het laatste geval, is er 1 gemeenschappelijke rekening. Gelukkig kan je adhv het kaartnummer of de optionele benaming altijd via de webapp opvolgen wie het saldo weer ontoereikend heeft gekregen 😊',
                'Er wordt momenteel ook gewerkt aan een optionele limiet per kaart, dus no worries.']
            },
            {
              question: 'Ik verlaat de club, ben ik mijn geld dan kwijt?',
              answers: ['Waarschijnlijk wel, net zoals met de ongebruikte bonnetjes. Dit is een keuze die door de club mag genomen worden, daar komen wij niet in tussen.']
            }
        ],
        screenshotsWebapp:  [
          {
            src: require('../assets/users-screenshots/nl/800/welcome.png')
          },
          {
            src: require('../assets/users-screenshots/nl/800/add-card.png')
          },
          {
            src: require('../assets/users-screenshots/nl/800/memberships.png')
          },
          {
            src: require('../assets/users-screenshots/nl/800/cardstop.png')
          },
          {
            src: require('../assets/users-screenshots/nl/800/topups.png')
          },
          {
            src: require('../assets/users-screenshots/nl/800/orders.png')
          },
          {
            src: require('../assets/users-screenshots/nl/800/rewards.png')
          }
        ]
      }
    },
  }
</script>
