import Vue from 'vue'

const $application = Vue.observable({
  urls: {
    users: process.env.VUE_APP_USERS_URL ? process.env.VUE_APP_USERS_URL : 'http://user.topupz.local:8004',
    tenants: process.env.VUE_APP_TENANTS_URL ? process.env.VUE_APP_TENANTS_URL : 'http://tenant.topupz.local:8002'
  }
})

export default $application
