import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import HomePage from './components/HomePage'
import UsersPage from './components/UsersPage'
import TenantsPage from './components/TenantsPage'
import $application from './application'

Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.mixin({
  beforeCreate() {
    this.$application = $application
  }
})

const routes = [
  { path: '/', component: HomePage  },
  { path: '/users', component: UsersPage },
  { path: '/tenants', component: TenantsPage }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
